<template>
  <div class="box">
    <div class="l_nav">
      <ul>
        <li @click="goIndex"><a href="javascript:;">首页 </a></li>
        <li><span>/</span></li>
        <li>{{ params.name }}</li>
      </ul>
    </div>
    <div class="content">
      <template v-if="query.navigationType == 1">
        <ul>
          <li v-for="(item, index) in dates" :key="index" @click="goDetails(item.id)">
<!--            <span class="circular" :style="'background:' + color"></span>-->
            <span class="liText">{{ item.journalisTitle }}</span>
            <span class="liTime">{{ toDate(item.createTime, "yyyy-MM-dd") }}</span>
          </li>
        </ul>
        <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-size="query.size" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
      </template>
      <template v-else>
        <div class="detailsBox">
          <div class="title">{{ details.journalisTitle }}</div>
          <div class="title_m">
            <span>信息来源：{{ details.message }}</span>
            <span>发布日期：{{ toDate(details.createTime, "yyyy-MM-dd") }}</span>
          </div>
          <div class="ql-editor" v-html="text"></div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { selectNavigaionList, getTextarea } from "@/api/template";
export default {
  name: "Transfer",
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      params: "",
      query: {
        current: 1,
        navigationId: "",
        navigationType: "",
        size: 15,
      },
      total: 0,
      dates: [],
      details: {},
      text: "",
    };
  },
  created() {
    // this.params = JSON.parse(window.sessionStorage.getItem("navigationValue"));
    // console.log(this.params,'list')
    this.params = this.$route.params
    this.init();
  },
  methods: {
    init() {
      this.query.navigationId = this.params.id;
      this.query.navigationType = this.params.type;
      selectNavigaionList(this.query).then((res) => {
        if (this.params.type == 1) {
          this.dates = res.data.records;
        } else {
          this.details = res.data;
          this.getTextareaFun(res.data.journalisContent);
        }
        this.total = res.data.total;
      }).catch(() => {});
    },
    getTextareaFun(journalisContent) {
      let params = {
        journalisContent: journalisContent,
      };
      getTextarea(params).then((res) => {
        this.text = res.data.dataHtml;
      });
    },
    handleSizeChange(size) {
      this.query.size = size;
      this.init();
    },
    handleCurrentChange(current) {
      this.query.current = current;
      this.init();
    },
    goIndex() {
      this.$router.push({name:'template-eighthMobile'})
    },
    goDetails(id) {
      let data = {
        name: this.params.name,
        id: id,
      };
      window.sessionStorage.setItem("detailsValue", JSON.stringify(data));
      this.$router.push("/eighthMobile-details");
    },
  },
};
</script>
<style lang="scss" scoped>
.box{
  width: 23rem;
  min-height: 27rem;
  background: #fff;
  margin: .5rem auto;
  padding: .5rem;
}
.l_nav {
  height: 2rem;
  //background-color: #f7f7f7;
  border-bottom: 1px #d2d2d2 solid;
  ul {
    width: 23rem;
    margin: 0 auto;
    li {
      float: left;
      line-height: 2rem;
      span {
        padding: 0 .5rem;
      }
    }
  }
}
.content {
  width: 23rem;
  min-height: 27rem;
  margin: .5rem auto 0;
  //padding: 20px;
  background: #fff;
  ul{
    min-height: 22rem;
    li {
      position: relative;
      cursor: pointer;
      height: 2rem;
      line-height: 2rem;
      color: #545454;
      font-size: 1rem;
      //padding-left: 1.2rem;
      .circular {
        display: inline-block;
        width: .4rem;
        height: .4rem;
        background: #1e3c5a;
        border-radius: 100%;
        vertical-align: middle;
        margin-right: .5rem;
        position: absolute;
        left: .5rem;
        top: 1rem;
      }
      .liText {
        display: inline-block;
        width: 15rem;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .liTime {
        float: right;
        width: 7rem;
        text-align: right;
      }
    }
  }

  .pagination {
    border-top: 1px solid #d2d2d2;
    padding-top: 20px;
    margin-top: 20px;
  }
  .detailsBox{
    padding: 0 1rem;
  }
  .title {
    color: #1e3c5a;
    font-size: 1.2rem;
    text-align: center;
    line-height: 2rem;
    font-weight: bold;
  }
  .title_m {
    text-align: center;
    border-bottom: 1px solid #d7d7d7;
    padding: .5rem;
    font-size: .5rem;
    span {
      margin-right: 1.5rem;
    }
  }
  .ql-editor {
    padding: .5rem 0;
    line-height: 1.5rem;
  }
}
.el-pager .active {
  color: #1e3c5a !important;
}
</style>
